import Vue from 'vue'
import App from './App.vue'
import firebase from '@/plugins/firebase'
import firebaseError from '@/plugins/firebase/error'
import axios from '@/plugins/axios'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import linkify from 'linkifyjs/html'
import vueFilterPrettyBytes from 'vue-filter-pretty-bytes'
import moment from 'moment'
import VueClipboard from 'vue-clipboard2'
import VuePromiseBtn from 'vue-promise-btn'
import 'vue-promise-btn/dist/vue-promise-btn.css'
import PasswordValidator from 'password-validator'
import GeneratePassword from 'generate-password'

// プロダクションヒント無効化
Vue.config.productionTip = false

// moment
moment.locale('ja')
Vue.prototype.$moment = moment
Vue.filter('moment', function (value, format) {
  if (value === null || value === undefined || value === '' || format === undefined) {
    return ''
  }
  if (format === 'from') {
    return moment(value).fromNow()
  }
  return moment(value).format(format)
})

// vue-clipboard2
Vue.use(VueClipboard)

// vue-promise-btn
Vue.use(VuePromiseBtn)

Vue.directive('linkified', (el) => { // NOTE: 短縮URLサービス乗り換えのためリンク先をリダイレクターに書き換え
  el.innerHTML = linkify(el.innerHTML)
  el.querySelectorAll('a').forEach((element) => {
    const aunUrl = element.getAttribute('href')
    if (new RegExp('^https://aun.page.link/').test(aunUrl)) {
      element.setAttribute('href', `https://aun-mypage.tools/redirect?url=${aunUrl}`)
    }
    if (element.getAttribute('target') === '_blank') {
      element.setAttribute('rel', 'noopener')
    }
  })
})

// vue-filter-pretty-bytes
Vue.use(vueFilterPrettyBytes)

// Global Mixin
Vue.mixin({
  data: () => ({
    inputRules: {
      required: value => /^[\s\S]+$/.test(value) || '必ず入力してください',
      requiredSelect: value => !!value || '必ず選択してください',
      alphabet: value => /^[\w-]{5,72}$/.test(value) || '半角英数字、ハイフン、アンダーバーのみ使用できます',
      nonNegativeNum: value => /^[0-9]\d*$/.test(value) || '0以上の数のみ使用可能です',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || '正しいメールアドレスを入力してください'
      },
      password: value => {
        return new PasswordValidator()
          .is().min(6)
          .is().max(72)
          .has().not().spaces()
          .validate(value) || '半角英数字記号、6～72文字で入力してください'
      }
    }
  }),
  methods: {
    userLogin: function (email, password) {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(async user => {
          // ログインユーザーをセット
          const res = await axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/login/me`)
          store.commit('setLoginUser', res.data)
          if (res.data.is_admin_user) {
            router.push({ name: 'admin:top' })
          } else if (res.data.is_end_user) {
            console.log(res.data.end_user)
            if (!res.data.end_user.current_end_user_plan) {
              // router.push({ name: 'TODO: error page' })
              store.commit('setFlashError', '有効期限が過ぎている為、ログインできません。')
            } else {
              router.push({ name: 'client:top' })
            }
          }
        })
        .catch(error => {
          store.commit('setFlashError', firebaseError(error, 'signin'))
          router.push({ name: 'login' })
        })
    },
    userSignOut: function () {
      firebase
        .auth()
        .signOut()
        .then(() => {
          router.push({ name: 'login' })
        })
        .catch(error => {
          store.commit('setFlashError', firebaseError(error, 'signout'))
          router.push({ name: 'login' })
        })
    },
    userPasswordReset: function (email) {
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          // Password reset email sent.
          store.commit('setFlashSuccess', 'パスワード設定メールが送信されました。')
        })
        .catch(error => {
          store.commit('setFlashError', firebaseError(error, 'passwordReset'))
        })
    },
    createApiValidErrorMessage: function (errorResponse) {
      let errorMessage = 'サーバーとの接続に失敗しました。'
      if (errorResponse?.data?.errors) {
        errorMessage = '<strong>' + errorResponse.data.message + '</strong><ul>'
        Object.keys(errorResponse.data.errors).forEach(key => {
          errorResponse.data.errors[key].forEach(message => {
            errorMessage += '<li>' + message + '</li>'
          })
        })
        errorMessage += '</ul>'
      }
      return errorMessage
    },
    generateUserPassword: function () {
      return GeneratePassword.generate({
        length: 10,
        numbers: true,
        symbols: true,
        uppercase: true,
        excludeSimilarCharacters: true
      })
    }
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
