import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'
import firebase from '@/plugins/firebase'
import axios from '@/plugins/axios'

Vue.use(VueRouter)

let routes = [
  {
    path: '/',
    redirect: { name: 'login' }
  },
  {
    path: '/web/admin',
    component: () => import('@/views/Admin.vue'),
    children: [
      {
        path: '/web/admin',
        redirect: { name: 'admin:top' }
      },
      {
        path: '/web/admin/top',
        name: 'admin:top',
        component: () => import('@/views/Admin/Dashboard.vue'),
        meta: { title: 'ダッシュボード' }
      },
      {
        path: '/web/admin/request/:id(\\d+)',
        name: 'admin:request_show',
        component: () => import('@/views/Admin/Request.vue'),
        meta: { title: '依頼詳細' }
      },
      {
        path: '/web/admin/request/:fillter',
        name: 'admin:request_index',
        component: () => import('@/views/Admin/Requests.vue'),
        meta: { title: '依頼一覧' }
      },
      {
        path: '/web/admin/request',
        redirect: { name: 'admin:request_index', params: { fillter: 'all' } }
      },
      {
        path: '/web/admin/direction/:id',
        name: 'admin:direction_show',
        component: () => import('@/views/Common/Direction.vue'),
        meta: { title: '制作案件詳細' }
      },
      {
        path: '/web/admin/direction',
        name: 'admin:direction_index',
        component: () => import('@/views/Admin/Directions.vue'),
        meta: { title: '制作案件一覧' }
      },
      {
        path: '/web/admin/client/new',
        name: 'admin:client_new_form',
        component: () => import('@/views/Admin/ClientNew.vue'),
        meta: { title: '新規クライアント登録' }
      },
      {
        path: '/web/admin/client/edit/:id(\\d+)',
        name: 'admin:client_edit_form',
        component: () => import('@/views/Admin/ClientNew.vue'),
        meta: { title: 'クライアント編集' }
      },
      {
        path: '/web/admin/client/:id(\\d+)',
        name: 'admin:client_show',
        component: () => import('@/views/Admin/Client.vue'),
        meta: { title: 'クライアント詳細' }
      },
      {
        path: '/web/admin/client/:fillter',
        name: 'admin:client_index',
        component: () => import('@/views/Admin/Clients.vue'),
        meta: { title: 'クライアント一覧' }
      },
      {
        path: '/web/admin/client/:end_id(\\d+)/login/:id(\\d+)',
        name: 'admin:client_edit_login',
        component: () => import('@/views/Admin/User.vue'),
        meta: { title: 'アカウント情報' }
      },
      {
        path: '/web/admin/client/:end_id(\\d+)/login/new',
        name: 'admin:client_new_login',
        component: () => import('@/views/Admin/User.vue'),
        meta: { title: '新規アカウント登録' }
      },
      {
        path: '/web/admin/client',
        redirect: { name: 'admin:client_index', params: { fillter: 'all' } }
      },
      {
        path: '/web/admin/plan',
        name: 'admin:plan_index',
        component: () => import('@/views/Admin/Plan.vue'),
        meta: { title: '保守プラン設定' }
      },
      {
        path: '/web/admin/point',
        name: 'admin:point_index',
        component: () => import('@/views/Admin/Service.vue'),
        meta: { title: 'サービス設定' }
      },
      {
        path: '/web/admin/holiday',
        name: 'admin:holiday_index',
        component: () => import('@/views/Admin/Holiday.vue'),
        meta: { title: '営業日設定' }
      },
      {
        path: '/web/admin/settings/status',
        name: 'admin:settings_status_show',
        component: () => import('@/views/Admin/Settings.vue'),
        meta: { title: 'システム設定' }
      },
      {
        path: '/web/admin/settings/login/:id(\\d+)',
        name: 'admin:settings_login_show',
        component: () => import('@/views/Admin/User.vue'),
        meta: { title: 'アカウント' }
      },
      {
        path: '/web/admin/settings/login',
        name: 'admin:settings_login_index',
        component: () => import('@/views/Admin/Accounts.vue'),
        meta: { title: 'アカウント一覧' }
      },
      {
        path: '/web/admin/settings/login/new',
        name: 'admin:settings_login_new_form',
        component: () => import('@/views/Admin/User.vue'),
        meta: { title: '新規アカウント登録' }
      },
      {
        path: '/web/admin/news/:id(\\d+)',
        name: 'admin:news_show',
        component: () => import('@/views/Admin/News/NewsContent.vue'),
        meta: { title: 'お知らせ' }
      },
      {
        path: '/web/admin/news',
        name: 'admin:news_index',
        component: () => import('@/views/Admin/News/News.vue'),
        meta: { title: 'お知らせ一覧' }
      },
      {
        path: '/web/admin/news/new',
        name: 'admin:news_new_form',
        component: () => import('@/views/Admin/News/NewsCreate.vue'),
        meta: { title: ' 新規お知らせ登録' }
      }
    ],
    meta: {
      requiresAdminAuth: true
    }
  },
  {
    path: '/web/client',
    component: () => import('@/views/Client.vue'),
    children: [
      {
        path: '/web/client',
        redirect: { name: 'client:top' }
      },
      {
        path: '/web/client/top',
        name: 'client:top',
        component: () => import('@/views/Client/Dashboard.vue'),
        meta: { title: 'ダッシュボード' }
      },
      {
        path: '/web/client/request/:id(\\d+)',
        name: 'client:request_show',
        component: () => import('@/views/Client/Request.vue'),
        meta: { title: '依頼詳細' }
      },
      {
        path: '/web/client/request/:fillter',
        name: 'client:request_index',
        component: () => import('@/views/Client/Requests.vue'),
        meta: { title: '依頼一覧' }
      },
      {
        path: '/web/client/request',
        redirect: { name: 'client:request_index', params: { fillter: 'all' } }
      },
      {
        path: '/web/client/direction/:id',
        name: 'client:direction_show',
        component: () => import('@/views/Common/Direction.vue'),
        meta: { title: '制作案件詳細' }
      },
      {
        path: '/web/client/direction',
        name: 'client:direction_index',
        component: () => import('@/views/Client/Directions.vue'),
        meta: { title: '制作案件一覧' }
      },
      {
        path: '/web/client/user/show_logins',
        name: 'client:user_show_logins',
        component: () => import('@/views/Client/Accounts.vue'),
        meta: { title: 'アカウント一覧' }
      },
      {
        path: '/web/client/user/:section',
        name: 'client:user_show',
        component: () => import('@/views/Client/Contract.vue'),
        meta: { title: '契約情報' }
      },
      {
        path: '/web/client/login/show/:id(\\d+)',
        name: 'client:login_show',
        component: () => import('@/views/Client/User.vue'),
        meta: { title: 'アカウント情報' }
      },
      {
        path: '/web/client/login/new',
        name: 'client:login_new_form',
        component: () => import('@/views/Client/User.vue'),
        meta: { title: '新規アカウント登録' }
      }
    ],
    meta: {
      requiresClientAuth: true
    }
  },
  {
    path: '/web/guest',
    component: () => import('@/views/Guest.vue'),
    children: [
      {
        path: '/web/guest/direction/:id',
        name: 'guest:direction_show',
        component: () => import('@/views/Common/Direction.vue'),
        meta: { title: '制作案件詳細' }
      }
    ],
    meta: {
      requiresGuestAuth: true
    }
  },
  {
    path: '/web/auth',
    component: () => import('@/views/Auth.vue'),
    children: [
      {
        path: '/web/auth',
        redirect: { name: 'login' }
      },
      {
        path: '/web/auth/login',
        name: 'login',
        component: () => import('@/views/Auth/Login.vue'),
        meta: { title: 'ログイン' }
      },
      {
        path: '/web/auth/direction/:id',
        name: 'login:direction',
        component: () => import('@/views/Auth/LoginDirection.vue'),
        meta: { title: 'ログイン（制作案件用）' }
      }
    ]
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue'),
    meta: { title: '404 | ページが見つかりませんでした' }
  }
]

// メンテナンス中の場合、メンテナンス画面にリダイレクト
if (process.env.VUE_APP_MAINTENANCE === 'true') {
  if (localStorage.getItem('debug') === null) {
    routes = [
      {
        path: '*',
        name: 'Maintenance',
        component: () => import('@/views/Maintenance.vue'),
        meta: { title: 'トコトン｜ただいまメンテナンス中です' }
      }
    ]
  }
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAdminAuth || record.meta.requiresClientAuth || record.meta.requiresGuestAuth)) {
    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        // ログインユーザーをセット
        if (store.state.login_user.id === undefined) {
          const res = await axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/login/me`)
          store.commit('setLoginUser', res.data)
        }
        // 権限によるアクセス制限
        if (store.state.login_user.is_admin_user && to.matched.some(record => record.meta.requiresAdminAuth)) {
          next()
        } else if (store.state.login_user.is_end_user && to.matched.some(record => record.meta.requiresClientAuth)) {
          next()
        } else if (store.state.login_user.is_guest_user && to.matched.some(record => record.meta.requiresGuestAuth)) {
          next()
        } else if (store.state.login_user.is_admin_user && to.name === 'client:request_show') { // 制作会社ユーザーが依頼詳細（クライアント）にアクセスした場合、依頼詳細（制作会社）にリダイレクト
          next({
            name: 'admin:request_show',
            params: to.params
          })
        } else if (store.state.login_user.is_end_user && to.name === 'admin:request_show') { // クライアントユーザーが依頼詳細（制作会社）にアクセスした場合、依頼詳細（クライアント）にリダイレクト
          next({
            name: 'client:request_show',
            params: to.params
          })
        } else if (store.state.login_user.is_admin_user && (to.name === 'client:direction_show' || to.name === 'guest:direction_show')) { // 制作会社ユーザーが制作案件詳細（クライアントorゲスト）にアクセスした場合、制作案件詳細（制作会社）にリダイレクト
          next({
            name: 'admin:direction_show',
            params: to.params
          })
        } else if (store.state.login_user.is_end_user && (to.name === 'admin:direction_show' || to.name === 'guest:direction_show')) { // クライアントユーザーが制作案件詳細（制作会社orゲスト）にアクセスした場合、制作案件詳細（制作会社）にリダイレクト
          next({
            name: 'client:direction_show',
            params: to.params
          })
        } else if (store.state.login_user.is_guest_user && (to.name === 'admin:direction_show' || to.name === 'client:direction_show')) { // ゲストユーザーが制作案件詳細（制作会社orクライアント）にアクセスした場合、制作案件詳細（ゲスト）にリダイレクト
          next({
            name: 'guest:direction_show',
            params: to.params
          })
        } else {
          next({
            name: 'login'
          })
        }
      } else if (to.name === 'admin:direction_show' || to.name === 'client:direction_show' || to.name === 'guest:direction_show') {
        next({
          name: 'login:direction',
          params: to.params
        })
      } else {
        next({
          name: 'login'
        })
      }
    })
  } else {
    next()
  }
})

const DEFAULT_TITLE = process.env.VUE_APP_TITLE
router.afterEach((to, from) => {
  document.title = `${to.meta.title}｜${DEFAULT_TITLE}` || DEFAULT_TITLE
})

export default router
