import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ja from 'vuetify/es5/locale/ja.js'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { ja },
    current: 'ja'
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#65aac1',
        secondary: '#7fb660',
        accent: '#e6a254',
        error: '#e97808',
        warning: '#e91e63',
        info: '#00bcd4',
        success: '#78b551',
        gray: '#9e9e9e'
      }
    },
    dark: false
  }
})
